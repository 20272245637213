import {
  ReinvestmentOption,
  useEditInvestmentMutation,
  useGetInvestmentQuery,
} from '__generated__';
import {
  Alert,
  Button,
  Card,
  Input,
  RadioButton,
  Select,
} from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { Col, Row } from 'react-grid-system';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import { Link, useNavigate, useParams } from 'react-router-dom';
import callMutationWithToastMessages from 'utils/callMutationWithToastMessages';
import formatCurrency from 'utils/formatCurrency';
import { setFieldProps } from 'utils/formHelpers';
import { numberMaskOptions } from 'utils/masks';
import { capitalize } from 'utils/stringFormatting';

import * as styles from './ReinvestmentElections.module.scss';
import { reinvestmentElectionSchema } from './validation';

const reinvestmentOptions = [
  { label: 'Full Reinvestment', value: 'full' },
  { label: 'Partial Reinvestment', value: 'partial' },
  { label: 'Opt-out', value: 'opt_out' },
];

const messages = {
  loading: 'Updating reinvestment elections',
  error: 'An error occurred while updating reinvestment elections',
  success: 'Reinvestment elections updated',
};

const ReinvestmentElections = () => {
  const { offeringId, investmentId } = useParams();
  const navigate = useNavigate();
  const [editReinvestment, editReinvestmentState] = useEditInvestmentMutation();
  const submitting = editReinvestmentState.loading;

  const { data, error, loading } = useGetInvestmentQuery({
    variables: {
      offeringId,
      investmentId,
    },
    fetchPolicy: 'no-cache',
  });

  const hasError = !!(error || data?.investment?.error);

  const investment = data?.investment?.investment;
  const investmentAccount = investment?.investmentAccount;
  const offering = investment?.offering?.offering;
  const destinationOffering = investment?.destinationOffering?.offering;
  const destinationClosing = destinationOffering?.closings?.closings[0];
  const sourceClosing = investment?.closing?.closing;
  const investmentIncrement = destinationOffering?.investmentIncrement
    ? destinationOffering.investmentIncrement
    : offering?.investmentIncrement;
  const minimumInvestment = destinationClosing?.minimumInvestmentAmount
    ? destinationClosing.minimumInvestmentAmount
    : sourceClosing?.minimumInvestmentAmount;
  const disableReinvestmentOptions = !!investment?.redemptionAmount;

  const defaultValues = useMemo(
    () => ({
      redemptionAmount: investment?.redemptionAmount || null,
      reinvestmentOption: investment?.reinvestmentOption || 'full',
      reinvestInterest: !!investment?.reinvestInterest,
      reinvestmentAmount: investment?.reinvestmentAmount || null,
    }),
    [investment],
  );

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(
      reinvestmentElectionSchema(
        investment?.amount,
        investmentIncrement,
        minimumInvestment,
      ),
    ),
    defaultValues,
  });

  const reinvestmentAmount = watch('reinvestmentAmount');
  const reinvestmentOption = watch('reinvestmentOption');
  const redemptionAmount = watch('redemptionAmount');
  const full = reinvestmentOption === ReinvestmentOption.Full;
  const partial = reinvestmentOption === ReinvestmentOption.Partial;
  const optOut = reinvestmentOption === ReinvestmentOption.OptOut;

  useEffect(() => {
    if (defaultValues) {
      reset({ ...defaultValues });
    }
  }, [defaultValues, reset]);

  useEffect(() => {
    if (optOut) {
      setValue('reinvestInterest', false);
      setValue('reinvestmentAmount', null);
    } else {
      setValue('reinvestInterest', defaultValues.reinvestInterest);
    }
  }, [optOut, setValue, defaultValues.reinvestInterest]);

  useEffect(() => {
    if (full) {
      setValue('reinvestmentAmount', null);
      setValue('redemptionAmount', null);
    }
  }, [full, setValue]);

  useEffect(() => {
    if (hasError) {
      toast.error('An error occurred while loading this investment');
      navigate(`/offerings/${offeringId}/investments`);
    }
  }, [hasError, navigate, offeringId]);

  const onSubmit = (values) => {
    if (values.reinvestmentOption === ReinvestmentOption.Full) {
      values.reinvestmentAmount = null;
      values.redemptionAmount = null;
    } else if (values.reinvestmentOption === ReinvestmentOption.OptOut) {
      values.reinvestmentAmount = null;
    }

    callMutationWithToastMessages(editReinvestment, messages, {
      variables: {
        investment: {
          id: investmentId,
          offeringId,
          reinvestInterest: values.reinvestInterest,
          reinvestmentAmount: parseFloat(values.reinvestmentAmount) || null,
          redemptionAmount: parseFloat(values.redemptionAmount) || null,
          reinvestmentOption: values.reinvestmentOption,
        },
      },
    }).then((res) => {
      if (res.data?.editInvestment?.investment?.id) {
        navigate(`/offerings/${offeringId}/investments`);
      }
    });
  };

  return (
    <>
      <h2 data-testid="heading">
        {loading ? (
          <Skeleton width={700} style={{ maxWidth: '100%' }} />
        ) : (
          investmentAccount &&
          `Edit Elections - ${investmentAccount?.entityName} (${capitalize(
            investmentAccount?.type,
          )})'s Investment in ${offering?.title}`
        )}
      </h2>
      {disableReinvestmentOptions && (
        <Alert type="warning">
          This investment has been flagged for pre-maturity redemption and
          reinvestment into another offering. Please reach out to PNE for
          questions or changes.
        </Alert>
      )}
      <Card>
        <Row>
          <Col md={6} className={styles.investmentInfo}>
            <p className="textLabel">Current Alpine Note Investment</p>
            {loading ? (
              <>
                <Skeleton width={350} style={{ maxWidth: '100%' }} />
                <Skeleton width={350} style={{ maxWidth: '100%' }} />
                <Skeleton width={350} style={{ maxWidth: '100%' }} />
                <Skeleton width={350} style={{ maxWidth: '100%' }} />
                <Skeleton width={350} style={{ maxWidth: '100%' }} />
              </>
            ) : (
              <>
                <div data-testid="currentNoteSeries">
                  <strong>Alpine Note Series:</strong> {offering?.title}
                </div>
                <div data-testid="investmentAmount">
                  <strong>Investment Amount:</strong>{' '}
                  {formatCurrency(investment?.amount)}
                </div>
                <div data-testid="anticipatedInterest">
                  <strong>Anticipated Interest Earned:</strong>{' '}
                  {formatCurrency(investment?.earnings)}
                </div>
                <div data-testid="anticipatedReferralBonus">
                  <strong>Anticipated Referral Bonus:</strong>{' '}
                  {investment?.referralBonus
                    ? formatCurrency(investment?.referralBonus)
                    : 'N/A'}
                </div>
                <div data-testid="currentMaturityDate">
                  <strong>Maturity Date:</strong>{' '}
                  {offering?.maturesOn
                    ? moment(new Date(offering?.maturesOn)).format('M/D/YYYY')
                    : 'N/A'}
                </div>
              </>
            )}
          </Col>
          {destinationOffering && (
            <Col md={6} className={styles.investmentInfo}>
              <p className="textLabel">Upcoming Alpine Note Investment</p>
              <div data-testid="destinationNoteSeries">
                <strong>Alpine Note Series:</strong>{' '}
                {destinationOffering?.title}
              </div>
              <div data-testid="effectiveDate">
                <strong>Effective Date:</strong>{' '}
                {destinationClosing?.dateInvested
                  ? moment(new Date(destinationClosing?.dateInvested)).format(
                      'M/D/YYYY',
                    )
                  : 'N/A'}
              </div>
              <div data-testid="destinationMaturityDate">
                <strong>Maturity Date:</strong>{' '}
                {destinationOffering?.maturesOn
                  ? moment(new Date(destinationOffering?.maturesOn)).format(
                      'M/D/YYYY',
                    )
                  : 'N/A'}
              </div>
            </Col>
          )}
        </Row>
        <h3>Reinvestment Elections</h3>
        {loading ? (
          <>
            <Skeleton width={350} style={{ maxWidth: '100%' }} />
            <Skeleton width={350} style={{ maxWidth: '100%' }} />
          </>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={4}>
                <Controller
                  name="reinvestmentOption"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...setFieldProps(field, errors)}
                      label="Reinvestment Option"
                      options={reinvestmentOptions}
                      data-testid="reinvestmentOptionSelect"
                      id="reinvestmentOptionSelect"
                      disabled={disableReinvestmentOptions}
                    />
                  )}
                />
              </Col>
            </Row>
            {partial && (
              <Row>
                <Col md={4}>
                  <Controller
                    name="reinvestmentAmount"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...setFieldProps(field, errors)}
                        value={field.value || ''}
                        label="Reinvestment Amount"
                        dollarMask
                        inputMaskOptions={numberMaskOptions}
                        disabled={disableReinvestmentOptions}
                      />
                    )}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col md={4}>
                <Controller
                  name="reinvestInterest"
                  control={control}
                  render={({ field }) => (
                    <div className="margin30">
                      <RadioButton
                        {...field}
                        id="reinvestInterestTrue"
                        label="Reinvest Interest Earned"
                        checked={field.value === true}
                        onChange={() => setValue('reinvestInterest', true)}
                        disabled={optOut || disableReinvestmentOptions}
                      />
                      <RadioButton
                        {...field}
                        id="reinvestInterestFalse"
                        label="Withdraw Interest Earned"
                        checked={field.value === false}
                        onChange={() => setValue('reinvestInterest', false)}
                        disabled={optOut || disableReinvestmentOptions}
                        errorMessage={
                          errors.reinvestInterest?.message as string
                        }
                      />
                    </div>
                  )}
                />
              </Col>
            </Row>
            <h3 className={full ? styles.disabled : ''}>
              Manual Redemption Elections
            </h3>
            <Row>
              <Col
                md={6}
                className={`${styles.investmentInfo} ${full ? styles.disabled : ''}`}
              >
                <div data-testid="remainderAmount">
                  <strong>Available Amount for Redemption:</strong>{' '}
                  {formatCurrency(investment.amount - reinvestmentAmount)}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Controller
                  name="redemptionAmount"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...setFieldProps(field, errors)}
                      value={field.value || ''}
                      label="Redemption Amount"
                      dollarMask
                      inputMaskOptions={numberMaskOptions}
                      disabled={full || disableReinvestmentOptions}
                    />
                  )}
                />
                {redemptionAmount && (
                  <div data-testid="remainingAmount">
                    <strong>Remaining Amount:</strong>{' '}
                    {formatCurrency(
                      investment.amount - reinvestmentAmount - redemptionAmount,
                    )}
                  </div>
                )}
              </Col>
            </Row>
            <Row className="marginTop30">
              <Col className="alignItemsCenter" xs={4}>
                <Link
                  className="textLink underline"
                  to={`/offerings/${offeringId}/investments`}
                >
                  Cancel
                </Link>
              </Col>
              <Col xs={8}>
                <Button
                  type="submit"
                  loading={submitting}
                  className="floatRight"
                >
                  Update
                </Button>
              </Col>
            </Row>
          </form>
        )}
      </Card>
    </>
  );
};

export default ReinvestmentElections;
